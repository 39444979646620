<template>
  <CForm @submit.prevent="login">
    <h1 class="text-center">
      FullHub
    </h1>
    <p class="text-center text-muted">
      Войдите в личный кабинет
    </p>
    <CAlert
      v-for="error in loginForm.errors.global"
      :key="error"
      color="danger"
      close-button
    >
      {{ error }}
    </CAlert>
    <CInput
      v-model="loginForm.data.email"
      placeholder="E-mail"
      required
      type="email"
    >
      <template #prepend-content>
        <CIcon name="cilUser" />
      </template>
    </CInput>
    <CInput
      v-model="loginForm.data.password"
      required
      type="password"
      placeholder="Пароль"
    >
      <template #prepend-content>
        <CIcon name="cilLockLocked" />
      </template>
    </CInput>
    <CRow align-vertical="center">
      <CCol col="auto">
        <CButton
          color="primary"
          type="submit"
          :disabled="loginForm.inProcessing"
        >
          <template v-if="loginForm.inProcessing">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="sr-only">Загрузка...</span>
          </template>
          <template v-else>
            Войти
          </template>
        </CButton>
      </CCol>
      <CCol>
        <CLink :to="{ name: 'recover' }">
          Восстановить пароль
        </CLink>
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import AuthService, { NoServiceRolesError } from '@/services/auth-service';

export default {
  name: 'Login',

  data() {
    return {
      loginForm: {
        data: {
          email: null,
          password: null,
        },
        errors: {
          data: {},
          global: [],
        },
        inProcessing: false,
      },
    };
  },

  methods: {
    async login() {
      this.loginForm.errors.global = [];

      this.loginForm.inProcessing = true;

      try {
        const data = {
          email: this.loginForm.data.email,
          locale: this.$store.state.auth.user.locale,
          password: this.loginForm.data.password,
        };

        await AuthService.loginByEmailAndPassword(data);

        await this.$router.push('admin/warehouses');
      } catch (e) {
        console.log(e);
        if (e instanceof NoServiceRolesError) {
          this.loginForm.errors.global.push('Нет доступа к сервису')
        }
        if (e.response) {
          if (e.response.status === 404) {
            this.loginForm.errors.global.push(
              'Неправильный логин или пароль',
            );
          } else {
            this.loginForm.errors.global.push(
              'Нет доступа',
            );
          }
        }
      } finally {
        this.loginForm.inProcessing = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
